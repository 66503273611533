<template>
  <list-container-widget
    :id="`member-by-type-${type.typeName}`"
    :title="translatedType"
    :view-more-location="getLocation('members')"
    :disable-view-more="disableViewMore"
    :type="type.key === 'noKey' ? null : type.key"
    :size="addOnSize"
    :stretch="false"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="community-members" />      
    </template>

    <members-by-type-list
      enable-card-size="xl"
      class="members-table"
      :members="members"
      :count="count"
      :total="total || 0"
      :is-loading="isLoading"
      @handleLoadOfNewItems="handleLoadOfNewItems"
      @total-updated="setAddOnSize($event)"
    />
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import MembersByTypeList from '@/views/apps/member/components/MembersByTypeList.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
// import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import vSelect from 'vue-select';

export default {
  name: 'MembersByTypeListWidget',
  components: {
    ListContainerWidget,
    MembersByTypeList,
    // WidgetActions,
    vSelect,    
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  props: {
    type: { type: Object, required: true },

    disableViewMore: Boolean,
    organizationKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      count: 8,
      isLoading: false,
      index: 0,
      timer: null,
      searchString: '',
      currentPage: 1,
      showfilter: false,
      isSomeFilterChanged: false,
    };
  },
  computed: {
    translatedType() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.typeMember);
    },
    typeMember() {
      return this.type?.typePlural?.en;
    },
    members() {
      if (this.$store.getters.communityMembers[this.typeMember]) {
        return this.$store.getters.communityMembers[this.typeMember].unpaginated;
      }
      return [];
    },
    total() {
      if (this.$store.getters.communityMembers[this.typeMember]) {
        return this.$store.getters.communityMembers[this.typeMember].meta.total;
      }
      return 0;
    },
  },
  async created() {
    //console.log('MembersByTypeListWidget');
    await this.fetchData({ page: this.currentPage, searchString: '', forceAPICall: true });
  },
  methods: {
    async fetchData({ page, searchString, forceAPICall = false }) {
      this.isLoading = true;
      if (searchString === '') {
        await this.$store.dispatch('getItems', {
          itemType: 'communityMembers',
          storedKey: this.typeMember,
          page,
          forceAPICall,
          requestConfig: {
            count: this.count,
            typeKey: this.type.typeKey,
            orderByDate: -1,
          },
        });
        this.isLoading = false;
      } else {
        await this.$store.dispatch('getItems', {
          itemType: 'communityMembers',
          storedKey: this.typeMember,
          page,
          forceAPICall,
          requestConfig: {
            count: this.count,
            typeKey: this.type.typeKey,
            orderByDate: -1,
            searchString,
          },
        });
        this.isLoading = false;
      }
    },
    async handleLoadOfNewItems({ page }) {
      if (!this.isLoading && this.members.length <= this.total) {
        await this.fetchData({ page, searchString: this.searchString, forceAPICall: true });
      }
    },
    // Search methods
    searchItems(value) {
      this.searchString = value;
      if (value == '') {
        return this.fetchData({ page: 1, searchString: '', forceAPICall: true });
      }
      const searchTerm = value.toLowerCase().trim();
      this.fetchData({ page: 1, searchString: searchTerm, forceAPICall: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.members-table {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex: 1;
}
</style>
