<template>
  <base-pagination-container
    class="members-list-container"
    :total="total"
    :loading="isLoading"
    :items="members"
    :per-page="count"
    :load-first-page="true"
    @load-page="handleLoadOfNewItems"
  >
    <template #default="{ items, loading }">
      <div v-if="isLoading" class="members w-100 align-items-center justify-content-center d-flex">
        <b-spinner class="my-5" variant="primary" />
      </div>
      <app-scroll class="members">
        <members-table :members="items" :busy="loading" />
      </app-scroll>
    </template>
  </base-pagination-container>
</template>

<script>
import BasePaginationContainer from '@core/components/containers/base/BasePaginationContainer.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import AppScroll from '@/@core/components/app-scroll/AppScroll.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import MembersTable from './MembersTable.vue';

export default {
  name: 'MembersByTypeList',
  components: {
    BasePaginationContainer,
    AppScroll,
    MembersTable,
    vSelect,
  },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  props: {
    members: { type: Array || Object, required: true },
    count: { type: Number, required: true },
    total: { type: Number, required: true },
    isLoading: { type: Boolean, required: true },
  },

  methods: {
    handleLoadOfNewItems({ page }) {
      this.$emit('handleLoadOfNewItems', { page });
    },
  },
};
</script>

<style lang="scss" scoped>
.members-list-container {
  overflow: hidden;
  flex: 1;
}
.toggle-filters-button {
  outline: none;
  box-shadow: none;
  min-width: 25px;
  margin-left: 10px;
}
.members {
  min-height: 451px;
}
</style>
